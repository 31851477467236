<template>
	<div class="web-tem-page">
		<div class="page1" :style="{height: page1hHeight}">

			<div class="header-box qz-fl-sb">
				<div>
					<img class="logo" src="@/assets/img/logo.png" alt="" srcset="" />
				</div>
				<div class="flex flex-row items-center gap-6"><a aria-label="twitter" target="_blank"
					rel="noopener norefferer nofollow"
					class=" flex max-w-[24px] flex-col items-center justify-center"
					href="https://x.com/KaiFirstAIMeme"><svg stroke="currentColor" fill="currentColor"
						stroke-width="0" viewBox="0 0 16 16" class="text-lg" height="1em" width="1em"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z">
						</path>
					</svg></a><a aria-label="telegram" target="_blank" rel="noopener norefferer nofollow"
					class=" flex max-w-[24px] flex-col items-center justify-center"
					href="https://t.me/KaiFirstAIMeme"><svg stroke="currentColor" fill="currentColor"
						stroke-width="0" viewBox="0 0 16 16" class="text-lg" height="1em" width="1em"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.287 5.906q-1.168.486-4.666 2.01-.567.225-.595.442c-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294q.39.01.868-.32 3.269-2.206 3.374-2.23c.05-.012.12-.026.166.016s.042.12.037.141c-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8 8 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629q.14.092.27.187c.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.4 1.4 0 0 0-.013-.315.34.34 0 0 0-.114-.217.53.53 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09">
						</path>
					</svg></a></div>
			</div>
			
			<div class="header-info">
				<div class="qz-fl-end">
					<div class="f-45 name">KAI First<span style="color: #2563eb;">AI</span></div>
					<div>
						<svg aria-hidden="true" viewBox="0 0 418 42"
							class="absolute left-0 top-2/3 h-[0.58em] w-full fill-blue-300/70"
							preserveAspectRatio="none">
							<path
								d="M203.371.916c-26.013-2.078-76.686 1.963-124.73 9.946L67.3 12.749C35.421 18.062 18.2 21.766 6.004 25.934 1.244 27.561.828 27.778.874 28.61c.07 1.214.828 1.121 9.595-1.176 9.072-2.377 17.15-3.92 39.246-7.496C123.565 7.986 157.869 4.492 195.942 5.046c7.461.108 19.25 1.696 19.17 2.582-.107 1.183-7.874 4.31-25.75 10.366-21.992 7.45-35.43 12.534-36.701 13.884-2.173 2.308-.202 4.407 4.442 4.734 2.654.187 3.263.157 15.593-.78 35.401-2.686 57.944-3.488 88.365-3.143 46.327.526 75.721 2.23 130.788 7.584 19.787 1.924 20.814 1.98 24.557 1.332l.066-.011c1.201-.203 1.53-1.825.399-2.335-2.911-1.31-4.893-1.604-22.048-3.261-57.509-5.556-87.871-7.36-132.059-7.842-23.239-.254-33.617-.116-50.627.674-11.629.54-42.371 2.494-46.696 2.967-2.359.259 8.133-3.625 26.504-9.81 23.239-7.825 27.934-10.149 28.304-14.005.417-4.348-3.529-6-16.878-7.066Z">
							</path>
						</svg>
					</div>
				</div>
				<div class="sy mt3 f-25">
					Art Powered by Imagination, Crafted by AI
				</div>
			</div>
			<div class="exhibit">
				<div class="scroll-containers" @mouseenter="stopScroll" @mouseleave="startScroll">
					<div class="box">
						<img class="ca-bj" src="@/assets/img/kai/1.jpg" alt="" srcset="" />
					</div>
					<div class="box">
						<img class="ca-bj" src="@/assets/img/kai/2.jpg" alt="" srcset="" />
					</div>
					<div class="box">
						<img class="ca-bj" src="@/assets/img/kai/3.jpg" alt="" srcset="" />
					</div>
					<div class="box">
						<img class="ca-bj" src="@/assets/img/kai/4.jpg" alt="" srcset="" />
					</div>
					<div class="box">
						<img class="ca-bj" src="@/assets/img/kai/5.jpg" alt="" srcset="" />
					</div>
					<!-- <div class="box">
						<img class="ca-bj" src="@/assets/img/kai/6.jpg" alt="" srcset="" />
					</div>
					<div class="box">
						<img class="ca-bj" src="@/assets/img/kai/7.jpg" alt="" srcset="" />
					</div>
					<div class="box">
						<img class="ca-bj" src="@/assets/img/kai/8.jpg" alt="" srcset="" />
					</div>
					<div class="box">
						<img class="ca-bj" src="@/assets/img/kai/9.jpg" alt="" srcset="" />
					</div> -->
				</div>
			</div>
		</div>




	</div>
</template>
<script>
	export default {
		data() {
			return {

				itemStyleHeight: "auto",
				bannerStyle: {
					w: "100%",
					h: "195px",
					pcH: "auto",
					bannerTop: "116px",
					bannerBottom: "250px"
				},
				page1hHeight: 'auto',
				scrollInterval: null,
				scrollPosition: 0,
			};
		},
		mounted() {
			this.updateWindowHeight()
			window.addEventListener('resize', this.updateWindowHeight);
			this.startScroll();
		},
		destroy() {},
		beforeDestroy() {
			window.removeEventListener('resize', this.updateWindowHeight);
		},
		methods: {
			updateWindowHeight() {
				this.page1hHeight = window.innerHeight + 'px';
			},

		},
	};
</script>

<style lang="scss" scoped>
	@import "../assets/styles/home.scss";
</style>